import {EventEmitter, Injectable} from '@angular/core';

export namespace MyMedax {
  export interface IntegrationApi {
    loadTheme(themeName: string): Promise<void>;
    assign(patient: Patient, questionnaires: Questionnaire[] | QuestionnairePayload[], submissions?: MyMedax.QuestionnaireSubmission): Promise<void>;
    assignForVariables(QuestionnairePayload, variables: any): Promise<void>;
    fill(questionnaireId: string): void;
    reset(): void;
  }

  export interface Patient {
    id: string;
    uid: string;
    customerNr: string;
    caseNr: string;
    gender: string;
    firstName: string;
    lastName: string;
    address: string;
    birthDate: string;
    location: string;
    createdAt: string;
  }

  export interface Questionnaire {
    id: string;
    status: string;
    revision: number;
    version?: string;
    softwareVersion: string;
    tenantId: string|number;
    meta: any;
    createdAt?: string;
    updatedAt?: string;
    questions: any;
  }

  export interface QuestionnairePayload {
    $schema: string;
    questionnaire: Questionnaire;
  }

  export interface QuestionnaireSubmission {
    id: string;
    uid: string;
    timestamp: string;
    meta: {revision: number, version: string};
    bucketId: string;
    answers: any;
    visibilities: any;
    variables: any;
    payload: QuestionnairePayload;
    patient: Patient;
  }
}

@Injectable()
export class MyMedaxService {
  private isReady: boolean = false;
  private frame: HTMLIFrameElement;
  private readyEvent: EventEmitter<any> = new EventEmitter<any>();
  private submitEvent: EventEmitter<{submissions: MyMedax.QuestionnaireSubmission[]}>
    = new EventEmitter<{submissions: MyMedax.QuestionnaireSubmission[]}>();

  public constructor() {
    (async () => {
      let readyEventFired = false;

      while (!this.getFrame()) {
        await this.sleep(100);
      }

      const contentWindow: any = this.getFrame().contentWindow;

      if (!contentWindow) {
        throw new Error('Unable to access content window');
      }

      const contentDocument = (this.getFrame().contentDocument || this.getFrame().contentWindow.document);

      const ref = setInterval(() => {
        if (readyEventFired) {
          this.isReady = true;
          clearTimeout(ref);
          return;
        }

        const isReady = !!contentWindow && !!contentWindow.integration && !!contentWindow.integration.ready;
        isReady && (readyEventFired = true) && this.readyEvent.emit();
      }, 100);

      contentDocument.addEventListener('integration-ready', () => {
        !readyEventFired && this.readyEvent.emit();
        readyEventFired = true;
      });

      contentDocument.addEventListener('integration-submit', (event: CustomEvent) =>
        this.onSubmit().emit(<{submissions: MyMedax.QuestionnaireSubmission[]}>event.detail));
    })();
  }

  public onReady(): EventEmitter<any> {
    // this will make sure, that we will always fire ready event, if
    // someone subscribes for it after it was already fired
    this.isReady && setTimeout(() => this.readyEvent.emit(), 10);
    return this.readyEvent;
  }

  public onSubmit(): EventEmitter<{submissions: MyMedax.QuestionnaireSubmission[]}> {
    return this.submitEvent;
  }

  public loadTheme(themeName: string): Promise<void> {
    return this.getIntegrationApi().loadTheme(themeName);
  }

  public assign(
    patient: MyMedax.Patient,
    questionnaires: MyMedax.Questionnaire[] | MyMedax.QuestionnairePayload[],
    submissions?: MyMedax.QuestionnaireSubmission[]
  ): Promise<void> {
    return this.getIntegrationApi().assign(patient, <any>questionnaires, <any>submissions);
  }

  public assignForVariables(QuestionnairePayload, variables: any): Promise<void> {
    return this.getIntegrationApi().assignForVariables(QuestionnairePayload, variables);
  }

  public fill(questionnaireId: string): void {
    this.getIntegrationApi().fill(questionnaireId);
  }

  public reset(): void {
    this.getIntegrationApi().reset();
  }

  private getFrame(): HTMLIFrameElement {
    if (!this.frame) {
      this.frame = document.querySelector('#mymedax-integration');
    }

    return this.frame;
  }

  private getIntegrationApi(): MyMedax.IntegrationApi {
    const contentWindow = this.getFrame().contentWindow;

    if (!contentWindow['integration']) {
      throw new Error('Unable to get integration api');
    }

    return contentWindow['integration'];
  }

  private sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), ms));
  }
}
