<div class="qa-config-generator-crud" [ngClass]="{'maximized': displayFullScreen}">
  <div class="config-generator-wrapper">
    <h2 class="mb-4" *ngIf="!displayFullScreen">Config Generator</h2>

    <div class="select-panel" *ngIf="!hidePanel">
      <div class="form-group">
        <label for="channel">Repository</label>
        <input class="form-control" type="text" readonly id="channel" value="{{templateUrl}}">
      </div>

      <div class="form-group">
        <label for="selectedVersion">Version</label>
        <select
          id="selectedVersion"
          class="form-control"
          name="selectedVersion"
          [(ngModel)]="selectedVersion"
        >
          <option value="" disabled selected>...</option>
          <option *ngFor="let version of versions" value="{{version}}">{{version}}</option>
        </select>
      </div>

      <div class="input-group btn-panel">
        <div class="input-group-addon add-variables">Variablen übernehmen</div>
        <label class="btn btn-success from-file">
          <i class="fa fa-upload"></i> Hochladen
          <input type="file" (change)="onUpload($event)" hidden>
        </label>
        <button class="btn btn-success from-clipboard" (click)="onUpload()" [disabled]="!hasClipboardApi || null">
          <i class="fa fa-clipboard"></i> Aus Zwischenablage
        </button>
        <div class="input-group-addon load-template ml-auto" id="from-file">Template</div>
        <button class="btn btn-success from-pack" (click)="onLoadPack()" [disabled]="!selectedVersion || !versions.length ? true: null">
          <i class="fa fa-download"></i> Laden
        </button>
      </div>
    </div>

    <br>
    <div *ngIf="form">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" [destroyOnHide]="false">
        <li [ngbNavItem]="1">
          <a ngbNavLink><i class="fa fa-cogs"></i> Konfiguration</a>
          <ng-template ngbNavContent>
            <div class="form-group" *ngIf="this.isWizardMode()">
              <iframe
                id="mymedax-integration"
                src="/assets/preview/?integrationMode=true&storageApi=memory"
                class="wizard"
              ></iframe>
            </div>

            <div *ngIf="form && !this.isWizardMode()">
              <div class="form-group" *ngIf="pack.template.modes && pack.template.modes.length">
                <label for="mode">Modus</label>
                <select
                  id="mode"
                  class="form-control"
                  name="mode"
                  [(ngModel)]="selectedMode"
                  (change)="onUpdateMode()"
                >
                  <option value="" disabled selected>...</option>
                  <option *ngFor="let mode of pack.template.modes" value="{{mode.id}}">{{mode.name}} ({{mode.description}})</option>
                </select>
                <br>
              </div>

              <form [formGroup]="form" class="template-form">
                <ng-container *ngFor="let varName of varNames">
                  <div class="form-group" *ngIf="pack.variables[varName].visible">
                    <label>
                      {{varName}}
                      <button
                        *ngIf="pack.variables[varName].isLicense"
                        [disabled]="form.get(varName).valid ? null : true"
                        (click)="onShowLicenseInfo(varName)"
                        class="btn btn-primary btn-sm"
                      >
                        <i class="fa fa-info-circle"></i>
                      </button>
                    </label>
                    <ng-container *ngIf="!pack.variables[varName].options; else select">
                      <input
                        class="form-control"
                        [formControlName]="varName"
                        name="{{varName}}"
                      >
                    </ng-container>
                    <ng-template #select>
                      <select
                        class="form-control"
                        [formControlName]="varName"
                        name="{{varName}}"
                      >
                        <option *ngFor="let option of pack.variables[varName].options" value="{{option}}">{{option}}</option>
                      </select>
                    </ng-template>
                    <small id="emailHelp" class="form-text text-muted">{{pack.variables[varName].info || ''}}</small>
                    <div class="invalid-feedback" *ngIf="form.get(varName).dirty && form.get(varName).errors && form.get(varName).errors[varName]">
                      {{pack.variables[varName].hint || ''}}
                    </div>
                  </div>
                </ng-container>

                <div class="group-control">
                  <button class="btn btn-primary" [disabled]="!form.valid" (click)="onGenerate()">
                    <i class="fa fa-cogs"></i>
                    Generieren
                  </button>
                </div>
              </form>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink><i class="fa fa-info"></i> Infos</a>
          <ng-template ngbNavContent>
            <div *ngIf="form">
              <div class="alert alert-info gray" role="alert">
                <strong>{{pack.template.description}}</strong>
                | Version: <strong>{{pack.template.version}}</strong>
                | Generiert: <strong>{{pack.template.name}}</strong><br>
              </div>
            </div>

            <div *ngIf="form">
              <ng-container *ngIf="pack.template.changelog">
                <label>Changelog</label>
                <div class="alert alert-info changelog gray" role="alert">
                  <ul>
                    <li *ngFor="let change of pack.template.changelog">{{change}}</li>
                  </ul>
                </div>
              </ng-container>

              <ng-container *ngIf="pack.template.versions">
                <label>Versionen</label>
                <div class="alert alert-info version-info gray" role="alert">
                  <ngx-json-viewer [json]="pack.template.versions" [expanded]="false"></ngx-json-viewer>
                </div>
              </ng-container>
            </div>


          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
